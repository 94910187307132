import { ReactComponent as Link } from "../../icons/contact-arrow.svg";
import { ReactComponent as Star } from "../../icons/star-big.svg";
import { ReactComponent as StarSmall } from "../../icons/star.svg";

function Contact() {
  const contact = "border border-black px-[20px] py-[10px] rounded-full";
  return (
    <div id="contact" className="pt-[100px] lg:pt-[200px]">
      <div className="border flex flex-wrap justify-between cursor-pointer items-center border-black text-black px-[20px] lg:px-[40px] py-[20px] rounded-[20px] lg:rounded-[50px] xl:rounded-full">
        <div className="flex items-center lg:space-x-[30px]">
          <Star className="hidden lg:block" />
          <StarSmall className="block lg:hidden mr-[20px]" />
          <h1 className="text-[40px] md:text-[50px] leading-[55px] lg:leading-normal lg:text-[70px]">Get in touch</h1>
        </div>

        <div className="flex flex-wrap space-x-[10px] justify-center text-[18px] lg:text-[20px] xl:text-[30px] font-semibold">
          <a href="mailto:josephdada15@gmail.com" className={contact}>
            Email
          </a>
          <a href="https://linkedin.com/in/josephttd" className={contact}>Linkedin</a>
          <a href="https://github.com/josephttd" className={contact}>Github</a>
        </div>
        <Link className="hidden 2xl:block" />
      </div>
    </div>
  );
}

export default Contact;
