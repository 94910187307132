import { Layout, Header, Work, Contact } from "../components";

export default function Home() {
  return (
    <div className="p-[5px] lg:p-[20px] 2xl:p-[40px]">
      <Layout>
        <Header />
        <Work />
        <Contact />
      </Layout>
    </div>
  );
}
