import Project from "./project";
import { ReactComponent as Star } from "../../icons/star.svg";
import Projects from "../../projects.json";

function Work() {
  return (
    <div id="work" className="mt-[100px]">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex items-center border space-x-[10px] border-black rounded-full w-fit px-[20px] mb-[35px]">
          <Star />
          <h2 className="text-[50px]">Work</h2>
        </div>

        <p className="text-[20px]">More work to be added*</p>
      </div>

      <div className="grid lg:grid-cols-2 gap-[55px]">
        {Projects.map((items, index) => {
          return (
            <div key={index}>
              <Project
                name={items.name}
                image={items.image}
                link={items.link}
                description={items.description}
                dark={items.dark}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Work;
