import { ReactComponent as Link } from "../../icons/project-link.svg";

function Project({ name, image, skills, link, description, dark }) {
  return (
    <div className={!image ? 'hidden lg:block relative' : `relative`}>
      <div className="bg-white h-[300px] lg:h-[490px] rounded-[30px] lg:rounded-[50px] overflow-hidden relative">
        {image && (
          <a
            href={link}
            className="cursor-pointer absolute right-[20px] z-10 w-[50px] lg:w-[77px] top-[20px]"
            target="_blank"
          >
            <Link />
          </a>
        )}
        
        {image && (
          <img src={image} className="w-full h-full object-cover object-top" />
        )}
        <div
          className={`top-[30px] lg:absolute left-[40px] ${
            dark ? "text-dark" : "text-white"
          }`}
        >
          <p className={[!image ? 'text-[40px] leading-[40px] flex items-center' : 'text-[30px]']}>{name}</p>
          <p className="text-[18px] w-full">{description}</p>
        </div>
        {skills}
        {link}
      </div>

      <div
        className={"text-dark block lg:hidden px-[20px] pt-[10px]"}
      >
        <p className={`text-[30px]`}>{name}</p>
        <p className="text-[18px] w-full">{description}</p>
      </div>
    </div>
  );
}

export default Project;
