import "../globals.css";
import { ReactComponent as One } from "../icons/one.svg";
import { ReactComponent as Two } from "../icons/two.svg";
import { ReactComponent as Three } from "../icons/three.svg";

function Layout({ children }) {
  const outline = "lg:border lg:p-[25px] sm:p-[10px] md:p-[15px] border-black";

  return (
    <div>
      <One className="fixed left-[-300px] bottom-[-400px]" />
      <Two className="fixed right-[-300px]"/>
      <Three className="fixed left-[-300px] top-[-400px]" />
      <div className={`${outline} rounded-[50px] p-0 lg:border lg:rounded-[108px] relative z-10`}>
        <div className={`${outline} rounded-[40px] md:border lg:rounded-[88px]`}>
          <div className={`${outline} rounded-[30px] p-[15px] border lg:rounded-[68px]`}>
            <div className="2xl:px-[40px] 2xl:py-[58px]">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
