import { ReactComponent as Star } from "../icons/star.svg";
import { ReactComponent as Arrow } from "../icons/arrow-link.svg";
import { useState, useEffect } from "react";

function Header() {
  //Hook for role
  const roles = ["Fullstack Developer", "Frontend Developer", "UI/UX Designer", "Product Engineer"];
  const [roleIndex, setRoleIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRoleIndex((prevIndex) => (prevIndex + 1) % roles.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [roles]);

  // Job title and company
  const job = {
    title: "Open to work.",
  };

  // Links data
  const links = [
    {
      link: "#work",
      text: "Work",
    },
    {
      link: "#contact",
      text: "Contact",
    },
  ];

  // Links Style
  const linksStyle = (text) => {
    return (
      <div className="text-[20px] font-bold flex space-x-[10px] items-center">
        <p>{text}</p>
        <span>
          <Arrow />
        </span>
      </div>
    );
  };

  // About me
  const seller =
    "Full Stack Developer & Experienced independent UI/UX designer based in London. Passionate about creating products that solve real world problems";

  // Content from here on out
  return (
    <div>
      <div className="lg:flex relative  items-center w-full mb-[26px] justify-between px-[18px] lg:pb-0 border pb-[30px] border-black rounded-[20px] lg:rounded-full">
        <div className="flex lg:block items-center justify-between py-[30px] lg:py-0">
          <h1 className="text-[60px] 2xl:text-[80px] leading-[70px] lg:leading-normal">
            Joseph Dada
          </h1>
          <img src="me.png" className="block lg:hidden" width="83" />
        </div>

        <Star className="hidden lg:block" />
        <div className="text-[20px] transform duration-150 ease-in-out flex items-center space-x-[20px] lg:block border font-bold border-black w-fit p-[18px] rounded-full">
          <Star className="block lg:hidden" />{" "}
          <p className="lg:!ml-[0]">{roles[roleIndex]}</p>
        </div>
        <Star className="hidden lg:block" />

        <div className="hidden xl:flex space-x-[35px]">
          {links.map((item, index) => {
            // Header links
            return (
              <a href={item.link} key={index}>
                {" "}
                {linksStyle(item.text)}
              </a>
            );
          })}
        </div>

        <img src="me.png" className="hidden lg:block" width="83" />
      </div>

      <div className="lg:grid grid-cols-12 mb-[50px]">
        <div className="flex flex-wrap items-center text-[20px] col-span-12">
          <h3 className="font-black">Currently </h3>
          <span className="border-b mx-[20px] border-black w-[40px]"></span>
          <span className="border border-black p-[10px] px-[20px] rounded-full">
            {job.title}
          </span>
        </div>
      </div>

      <div className="lg:grid grid-cols-12">
        <div className="lg:col-start-9 lg:col-end-13">
          <p className="text-[21px]">{seller}</p>
        </div>
      </div>
    </div>
  );
}

export default Header;
